#root {
  height: 100%;
}

html {
  height: 100%;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  *,
  &::before,
  &:after {
    box-sizing: inherit;
    -webkit-overflow-scrolling: touch;
  }
}

body {
  margin: 0;
  height: 100%;
  color: #fff;
  background: #151515;
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
}

// pull-to-refresh styles >>>>>
.ptr--ptr.ptr--pull.ptr--release {
  position: relative;
  transform: translateY(60px);
}
.ptr--ptr.ptr--refresh {
  min-height: 0px !important;
  position: relative;
  transform: translateY(60px);
}
.ptr--text {
  display: none;
  height: 0;
  color: white !important;
}
.ptr--icon {
  color: white !important;
}
// pull-to-refresh styles <<<<<

a {
  text-decoration: none;
}

p {
  margin: 0;
}

svg {
  fill: currentColor;
  &.color--secondary {
    color: rgba(255, 255, 255, 0.4);
  }
}

input,
textarea,
select {
  &:-webkit-autofill,
  &:-webkit-autofill,
  &:-webkit-autofill {
    color: #fff;
    -webkit-text-fill-color: #fff;
    transition: background-color 999999s;
    &:hover,
    &:focus {
      color: #fff;
      -webkit-text-fill-color: #fff;
    }
  }

  &::-webkit-input-placeholder {
    color: yellow;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Removing Arrows/Spinners from inputs
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

// Changing selected text's color
::selection {
  background: rgba(255, 255, 255, 0.2);
}
::-moz-selection {
  background: rgba(255, 255, 255, 0.2);
}

// Disabling blue highlight when Touch/Press object with rule 'cursor: pointer'
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
